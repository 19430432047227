import {
  REQUESTING_VIDEOGRAPHER_DETAILS_BY_STOREFRONT,
  RECEIVED_VIDEOGRAPHER_DETAILS,
  UPDATING_VIDEOGRAPHER_DETAILS,
  UPDATED_VIDEOGRAPHER_DETAILS,
  RequestingVideographerDetailsByStorefrontAction,
  ReceivedVideographerDetailsAction,
  VideographerActionType,
  UpdatingVideographerDetailsAction,
  UpdatedVideographerDetailsAction,
} from '~/actions/types/videographerActionTypes';
import type { AppDispatch, AppThunk } from '~/reducers';
import { VideographerDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';
import { getStorefrontVideoGallery as getVendorVideoGallery } from './vendorStorefrontVideoActions';

function requestingVideographerDetailsByStorefront(): RequestingVideographerDetailsByStorefrontAction {
  return {
    type: REQUESTING_VIDEOGRAPHER_DETAILS_BY_STOREFRONT,
  };
}

function receivedVideographerDetails(
  videographerDetails: VideographerDetails
): ReceivedVideographerDetailsAction {
  return {
    type: RECEIVED_VIDEOGRAPHER_DETAILS,
    payload: videographerDetails,
  };
}

type VideographerCallback = (dispatch: AppDispatch) => unknown;

function getVideographer(
  url: string,
  type: VideographerActionType,
  callback: VideographerCallback | null = null
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(type);
    return ApiService.get(url)
      .then((videographerDetails) => {
        dispatch(receivedVideographerDetails(videographerDetails));
        if (callback) {
          dispatch(callback);
        }
      })
      .catch((error) => Logger.error(error.message, error));
  };
}

/**
 * Get videographer details by storefrontId
 *
 * @param {Object} storefront
 * @param {String} storefront.uuid
 */
export function getVideographerDetailsByStorefront(storefront: {
  uuid: string;
}): AppThunk<Promise<void>> {
  return getVideographer(
    `/web-marketplace-api/v1/manage/videographer/for-storefront/${storefront.uuid}`,
    requestingVideographerDetailsByStorefront(),
    getVendorVideoGallery(storefront.uuid)
  );
}

/* Update videographer details by id
 * everything must be included or it nulls the field
 */
export function updatingVideographerDetails(): UpdatingVideographerDetailsAction {
  return {
    type: UPDATING_VIDEOGRAPHER_DETAILS,
  };
}

export function updatedVideographerDetails(
  response: VideographerDetails
): UpdatedVideographerDetailsAction {
  return {
    type: UPDATED_VIDEOGRAPHER_DETAILS,
    payload: response,
  };
}

export function updateVideographerDetails(
  videographer: VideographerDetails
): AppThunk<Promise<void | UpdatedVideographerDetailsAction>> {
  return (dispatch) => {
    dispatch(updatingVideographerDetails());
    return ApiService.put(
      `/web-marketplace-api/v1/manage/videographer/${videographer.uuid}`,
      videographer
    )
      .then((json) => dispatch(updatedVideographerDetails(json)))
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
}
