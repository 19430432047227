import {
  REQUESTING_BAKER_DETAILS_BY_STOREFRONT,
  RECEIVED_BAKER_DETAILS,
  UPDATING_BAKER_DETAILS,
  UPDATED_BAKER_DETAILS,
  RequestingBakerDetailsByStorefrontAction,
  ReceivedBakerDetailsAction,
  BakerActionType,
  UpdatedBakerDetailsAction,
  UpdatingBakerDetailsAction,
} from '~/actions/types/bakerActionTypes';
import type { AppThunk } from '~/reducers';
import type { BakerDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';

const requestingBakerDetailsByStorefront = (): RequestingBakerDetailsByStorefrontAction => {
  return {
    type: REQUESTING_BAKER_DETAILS_BY_STOREFRONT,
  };
};

export const receivedBakerDetails = (bakerDetails: BakerDetails): ReceivedBakerDetailsAction => {
  return {
    type: RECEIVED_BAKER_DETAILS,
    payload: bakerDetails,
  };
};

const getBaker = (url: string, type: BakerActionType): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(type);
    return ApiService.get(url)
      .then((bakerDetails) => {
        dispatch(receivedBakerDetails(bakerDetails));
      })
      .catch((error) => Logger.error(error.message, error));
  };
};

export const getBakerDetailsByStorefront = (storefront: { uuid: string }) => {
  return getBaker(
    `/web-marketplace-api/v1/manage/baker/for-storefront/${storefront.uuid}`,
    requestingBakerDetailsByStorefront()
  );
};

/* Update baker details by id
 * everything must be included or it nulls the field
 */
const updatingBakerDetails = (): UpdatingBakerDetailsAction => {
  return {
    type: UPDATING_BAKER_DETAILS,
  };
};

export const updatedBakerDetails = (response: BakerDetails): UpdatedBakerDetailsAction => {
  return {
    type: UPDATED_BAKER_DETAILS,
    payload: response,
  };
};

export const updateBakerDetails = (
  baker: BakerDetails
): AppThunk<Promise<UpdatedBakerDetailsAction | void>> => {
  return (dispatch) => {
    dispatch(updatingBakerDetails());
    return ApiService.put(`/web-marketplace-api/v1/manage/baker/${baker.uuid}`, baker)
      .then((json) => dispatch(updatedBakerDetails(json)))
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};
