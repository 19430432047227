import {
  ReviewRequestView,
  ReviewView,
  StorefrontGalleryView,
} from '@zola/svc-marketplace-ts-types';

import type { AppDispatch } from '~/reducers';
import {
  EntityFacetView,
  MappedGalleryPhotoView,
  MappedOptionFacetView,
  VenueDetails,
  VenueSpaceView,
  MappedVenueMenuView,
} from '~/types/responseTypes';
import {
  CouplesStorefrontDetails,
  CouplesStorefrontDetailsVenue,
  MappedStorefrontFaqView,
  VendorStorefrontDetails,
  VendorStorefrontDetailsVenue,
} from '~/types/storefrontDetails';

import StorefrontDetailsBuilder from '../storefrontDetailsBuilder';

/**
 * Fetches galleries and options for all the spaces in the venue
 *
 * @param {*} storefront
 * @param {*} dispatch
 * @param {*} getVenueSpaceGallery
 * @param {*} getVenueSpaceOptions
 */
function getVenueSpaceDetails<
  T extends CouplesStorefrontDetailsVenue | VendorStorefrontDetailsVenue
>(
  storefront: T,
  dispatch: AppDispatch,
  getVenueSpaceGallery: (dispatch: AppDispatch, uuid: string) => Promise<MappedGalleryPhotoView[]>,
  getVenueSpaceOptions: (dispatch: AppDispatch, uuid: string) => Promise<MappedOptionFacetView[]>
) {
  const { spaces } = storefront.venueDetails;

  const promises: [Promise<MappedGalleryPhotoView[]>?, Promise<MappedOptionFacetView[]>?] = [];
  spaces?.forEach((space) => {
    promises.push(getVenueSpaceGallery(dispatch, space.uuid));
    promises.push(getVenueSpaceOptions(dispatch, space.uuid));
  });

  return Promise.all(promises).then(() =>
    new StorefrontDetailsBuilder<T>(storefront).withSpaces(spaces || []).build()
  );
}

/**
 * Fetches the venue, spaces, and menus for a storefront using either the manage or public endpoints
 */
function getVenueDetails<T extends CouplesStorefrontDetailsVenue | VendorStorefrontDetailsVenue>(
  storefront: T,
  getVenue: (uuid: string) => Promise<VenueDetails>,
  getVenueSpaces: (uuid: string) => Promise<VenueSpaceView[]>,
  getVenueMenus: (uuid: VenueDetails) => Promise<MappedVenueMenuView[]>
) {
  const { uuid } = storefront;

  return getVenue(uuid).then((venue) =>
    Promise.all([getVenueSpaces(venue.uuid), getVenueMenus(venue)]).then(([spaces, menus]) =>
      new StorefrontDetailsBuilder<T>(storefront)
        .withVenue(venue)
        .withSpaces(spaces)
        .withMenus(menus)
        .build()
    )
  );
}

function getAdditionalDetails<T extends CouplesStorefrontDetails | VendorStorefrontDetails>(
  storefront: T,
  getPhotoGallery: (uuid: string) => Promise<StorefrontGalleryView[]>,
  getOptions: (uuid: string) => Promise<EntityFacetView[]>,
  getFaqs: (uuid: string) => Promise<MappedStorefrontFaqView[]>,
  getReviews?: (uuid: string) => Promise<ReviewRequestView[]>,
  getSubmittedReviews?: (uuid: string) => Promise<ReviewView[]>
) {
  const { uuid } = storefront;
  const getAdditionalDetailsRequests: [
    Promise<StorefrontGalleryView[]>,
    Promise<EntityFacetView[]>,
    Promise<MappedStorefrontFaqView[]>,
    Promise<ReviewRequestView[]>?,
    Promise<ReviewView[]>?
  ] = [getPhotoGallery(uuid), getOptions(uuid), getFaqs(uuid)];

  if (typeof getReviews === 'function') {
    getAdditionalDetailsRequests.push(getReviews(uuid));
  }
  if (typeof getSubmittedReviews === 'function') {
    getAdditionalDetailsRequests.push(getSubmittedReviews(uuid));
  }
  return Promise.all(getAdditionalDetailsRequests).then(
    ([photoGallery, options, faqs, reviews, submittedReviews]) =>
      new StorefrontDetailsBuilder<T>(storefront)
        .withPhotoGallery(photoGallery)
        .withOptions(options)
        .withFaqs(faqs)
        .withReviews(reviews || null)
        .withSubmittedReviews(submittedReviews || null)
        .build()
  );
}

export { getAdditionalDetails, getVenueDetails, getVenueSpaceDetails };
