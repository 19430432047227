import { Action } from 'redux';

import {
  REQUESTING_BEAUTICIAN_DETAILS_BY_STOREFRONT,
  RECEIVED_BEAUTICIAN_DETAILS,
  UPDATING_BEAUTICIAN_DETAILS,
  UPDATED_BEAUTICIAN_DETAILS,
  RequestingBeauticianDetailsForStorefrontAction,
  ReceivedBeauticianDetailsAction,
  UpdatingBeauticianDetailsAction,
  UpdatedBeauticianDetailsAction,
} from '~/actions/types/beauticianActionTypes';
import type { AppThunk } from '~/reducers';
import type { BeauticianDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';

const requestingBeauticianDetailsByStorefront =
  (): RequestingBeauticianDetailsForStorefrontAction => {
    return {
      type: REQUESTING_BEAUTICIAN_DETAILS_BY_STOREFRONT,
    };
  };

export const receivedBeauticianDetails = (
  beauticianDetails: BeauticianDetails
): ReceivedBeauticianDetailsAction => {
  return {
    type: RECEIVED_BEAUTICIAN_DETAILS,
    payload: beauticianDetails,
  };
};

/**
 * Fetches the beautician details, if they exist, for the given storefront,
 * saving the results to redux when the api action completes.
 *
 * This is part of the vendors interface, but it put the results into the
 * same slice of state as couples.
 *
 * @param storefrontUuid
 */
export const getBeauticianDetailsByStorefront = (
  storefrontUuid: string
): AppThunk<Promise<void | ReceivedBeauticianDetailsAction>> => {
  return (dispatch) => {
    dispatch(requestingBeauticianDetailsByStorefront());
    return ApiService.get(
      `/web-marketplace-api/v1/manage/beautician/for-storefront/${storefrontUuid}`
    )
      .then((beauticianDetails: BeauticianDetails) => {
        return dispatch(receivedBeauticianDetails(beauticianDetails));
      })
      .catch((error) => {
        Logger.error(error.message, error);
        return Promise.resolve();
      });
  };
};

/* Update beautician details by id
 * everything must be included or it nulls the field
 */
const updatingBeauticianDetails = (): UpdatingBeauticianDetailsAction => {
  return {
    type: UPDATING_BEAUTICIAN_DETAILS,
  };
};

const updatedBeauticianDetails = (response: BeauticianDetails): UpdatedBeauticianDetailsAction => {
  return {
    type: UPDATED_BEAUTICIAN_DETAILS,
    payload: response,
  };
};

export const updateBeauticianDetails = (
  beautician: BeauticianDetails
): AppThunk<Promise<Action<string> | UpdatedBeauticianDetailsAction>> => {
  return (dispatch) => {
    dispatch(updatingBeauticianDetails());
    return ApiService.put(
      `/web-marketplace-api/v1/manage/beautician/${beautician.uuid}`,
      beautician
    )
      .then((json) => {
        return dispatch(updatedBeauticianDetails(json));
      })
      .catch((error) => {
        Logger.error(error.message, error);
        return dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};
