import {
  REQUESTING_MUSICIAN_DETAILS_BY_STOREFRONT,
  RECEIVED_MUSICIAN_DETAILS,
  UPDATING_MUSICIAN_DETAILS,
  UPDATED_MUSICIAN_DETAILS,
  ReceivedMusicianDetailsAction,
  RequestingMusicianDetailsByStorefrontAction,
  MusicianActionType,
  UpdatingMusicianDetailsAction,
  UpdatedMusicianDetailsAction,
} from '~/actions/types/musicianActionTypes';
import type { AppDispatch, AppThunk } from '~/reducers';
import { MusicianDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';
import { getStorefrontVideoGallery as getVendorVideoGallery } from './vendorStorefrontVideoActions';

const requestingMusicianDetailsByStorefront = (): RequestingMusicianDetailsByStorefrontAction => {
  return {
    type: REQUESTING_MUSICIAN_DETAILS_BY_STOREFRONT,
  };
};

export const receivedMusicianDetails = (
  musicianDetails: MusicianDetails
): ReceivedMusicianDetailsAction => {
  return {
    type: RECEIVED_MUSICIAN_DETAILS,
    payload: musicianDetails,
  };
};

type MusicianCallback = (dispatch: AppDispatch) => unknown;

const getMusician = (
  url: string,
  type: MusicianActionType,
  callback: MusicianCallback | null = null
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(type);
    return ApiService.get(url)
      .then((musicianDetails) => {
        dispatch(receivedMusicianDetails(musicianDetails));
        if (callback) {
          dispatch(callback);
        }
      })
      .catch((error) => Logger.error(error.message, error));
  };
};
// Get musician details by storefrontId
export const getMusicianDetailsByStorefront = (storefront: {
  uuid: string;
}): AppThunk<Promise<void>> => {
  return getMusician(
    `/web-marketplace-api/v1/manage/musician/for-storefront/${storefront.uuid}`,
    requestingMusicianDetailsByStorefront(),
    getVendorVideoGallery(storefront.uuid)
  );
};

/* Update musician details by id
 * everything must be included or it nulls the field
 */
const updatingMusicianDetails = (): UpdatingMusicianDetailsAction => {
  return {
    type: UPDATING_MUSICIAN_DETAILS,
  };
};

export const updatedMusicianDetails = (response: MusicianDetails): UpdatedMusicianDetailsAction => {
  return {
    type: UPDATED_MUSICIAN_DETAILS,
    payload: response,
  };
};

export const updateMusicianDetails = (
  musician: MusicianDetails
): AppThunk<Promise<void | UpdatedMusicianDetailsAction>> => {
  return (dispatch) => {
    dispatch(updatingMusicianDetails());
    return ApiService.put(`/web-marketplace-api/v1/manage/musician/${musician.uuid}`, musician)
      .then((json) => dispatch(updatedMusicianDetails(json)))
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};
