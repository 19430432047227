import {
  StorefrontVideoGalleryThumbnailView,
  StorefrontVideoGalleryView,
  StorefrontVideoViewResponse,
} from '@zola/svc-marketplace-ts-types';

import {
  SaveSortablePhotoRequest,
  saveSortablePhotos,
} from '~/actions/vendors/vendorStorefrontPhotoActions';
import type { AppDispatch, AppThunk } from '~/reducers';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';
import * as ActionTypes from './types/vendorStorefrontVideoActionTypes';

type SortableStorefrontVideoGalleryThumbnailView = SaveSortablePhotoRequest &
  StorefrontVideoGalleryThumbnailView;
export const MAX_VIDEO_GALLERY_ENTRIES = 8;

const creatingStorefrontVideo = () => ({
  type: ActionTypes.CREATING_STOREFRONT_VIDEO,
});

const createdStorefrontVideo = (index: number, video: StorefrontVideoViewResponse) => ({
  type: ActionTypes.CREATED_STOREFRONT_VIDEO,
  payload: { video, index },
});

/**
 * Creates a storefront video, not connected to the gallery, for the storefront
 *
 * @param {Object} storefrontVideo
 * @param {Number} storefrontVideo.index - which entry in the gallery
 * @param {String} storefrontVideo.host - Where is the video hosted: YOUTUBE | VIMEO
 * @param {String} storefrontVideo.hostId - identifier for the video on the host site
 * @param {String} storefrontVideo.title - user provided title for the video
 * @param {String} storefrontVideo.type - enumerated type (length) of video:  "TRAILER" | "HIGHLIGHT_REEL" |  "SHORT_FEATURE" | "LONG_FEATURE"
 * @param {String} storefrontVideo.thumbnailPhotoUuid - image uuid of the thumbnail photo
 * @param {String} storefrontVideo.storefrontUuid
 *
 */
const createStorefrontVideo = (storefrontVideo: {
  index: number;
  host: string;
  hostId: string;
  title: string;
  type: string;
  thumbnailPhotoUuid: string;
  storefrontUuid: string;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch(creatingStorefrontVideo());
    return ApiService.post<StorefrontVideoViewResponse>(
      '/web-marketplace-api/v1/manage/storefront-video',
      storefrontVideo
    )
      .then((response) => {
        dispatch(createdStorefrontVideo(storefrontVideo.index, response));
        return response;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: `Error creating video` }));
        return null;
      });
  };
};

const requestingStorefrontVideoGallery = () => ({
  type: ActionTypes.REQUESTING_STOREFRONT_VIDEO_GALLERY,
});
const receivedStorefrontVideoGallery = (type: string, gallery: StorefrontVideoGalleryView[]) => ({
  type,
  payload: gallery,
});

/**
 * Retrieves the video gallery for a storefront
 *
 * @param {*} storefrontUuid
 */
export const getStorefrontVideoGallery = (storefrontUuid: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(requestingStorefrontVideoGallery());
    return ApiService.get<StorefrontVideoGalleryView[]>(
      `/web-marketplace-api/v1/manage/storefront-video/${storefrontUuid}/gallery`
    )
      .then((gallery) => {
        dispatch(
          receivedStorefrontVideoGallery(ActionTypes.RECEIVED_STOREFRONT_VIDEO_GALLERY, gallery)
        );
        return gallery;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        /** What should we do in the error case?  Error loading storefront? */
        return null;
      });
  };
};

/**
 * Does the heaving lifting of creating a storefront video entry
 * - Requires: a temp image uploaded to s3 as the thumbnail
 * - Uploads the temp image to svc-image
 * - Create a storefront-photos entry
 * - Creates and returns a storefront-video
 *
 *
 * @param {Object} entry
 * @param {String} entry.host - Where is the video hosted: YOUTUBE | VIMEO
 * @param {String} entry.hostId - identifier for the video on the host site
 * @param {String} entry.title - user provided title for the video
 * @param {String} entry.type - enumerated type (length) of video:  "TRAILER" | "HIGHLIGHT_REEL" |  "SHORT_FEATURE" | "LONG_FEATURE"
 * @param {String} entry.storefrontUuid
 * @param {Number} entry.index
 * @param {Object} entry.thumbnail - a temp image (created in s3, but not uploaded to svc-image / svc-marketplace)
 * @param {String} entry.thumbnail.imageUrl
 * @param {String} entry.thumbnail.height
 * @param {String} entry.thumbnail.width
 * @param {String} entry.thumbnail.s3Bucket
 * @param {String} entry.thumbnail.s3Key
 * @param {String} entry.thumbnail.contentType
 * @param {String} entry.thumbnail.fileName
 * @param {String} entry.thumbnail.newPhoto
 *
 * @return {Object} storefrontVideo
 * @return {String} storefrontVideo.uuid
 * @return {String} storefrontVideo.storefrontUuid
 * @return {Object} storefrontVideo.thumbnail
 * @return {String} storefrontVideo.thumbnail.imageId
 * @return {Number} storefrontVideo.thumbnail.height
 * @return {Number} storefrontVideo.thumbnail.width
 * @return {String} storefrontVideo.host
 * @return {String} storefrontVideo.hostId
 * @return {String} storefrontVideo.type
 * @return {String} storefrontVideo.title
 *
 */
export const saveVideoGalleryEntry = (entry: {
  host: string;
  hostId: string;
  title: string;
  type: string;
  storefrontUuid: string;
  index: number | null;
  thumbnail: StorefrontVideoGalleryThumbnailView | null;
}): AppThunk<Promise<StorefrontVideoViewResponse | null>> => {
  const { thumbnail } = entry;
  return (dispatch: AppDispatch) => {
    // return a function that takes dispatch
    return dispatch(
      // when invoked, it dispatches save sortable photos to create the storefront-photo
      saveSortablePhotos(
        'video_gallery',
        [thumbnail as SortableStorefrontVideoGalleryThumbnailView],
        (savedPhotos) => {
          return dispatch(
            // then it creates the storefront video
            createStorefrontVideo({
              host: entry.host,
              hostId: entry.hostId,
              title: entry.title,
              type: entry.type,
              thumbnailPhotoUuid:
                savedPhotos[0].uuid ||
                ((savedPhotos[0] as SortableStorefrontVideoGalleryThumbnailView).imageId as string),
              storefrontUuid: entry.storefrontUuid,
              index: entry.index as number,
            })
          );
        }
      )
    );
  };
};

const replacingVideoGallery = (storefrontUuid: string) => ({
  type: ActionTypes.REPLACING_STOREFRONT_VIDEO_GALLERY,

  payload: {
    storefrontUuid,
  },
});

/**
 * Replace the video gallery with a new gallery
 *
 * @param {Array<String>} entries - the uuids of the storefront videos to set as the gallery
 */
export const replaceVideoGallery = (
  storefrontUuid: string,
  entries: Array<string>
): AppThunk<Promise<StorefrontVideoGalleryView[]>> => {
  const request = {
    storefrontUuid,
    gallery_entries: entries.map((uuid) => ({
      video_uuid: uuid,
    })),
  };

  return (dispatch: AppDispatch) => {
    dispatch(replacingVideoGallery(storefrontUuid));
    return ApiService.post(
      '/web-marketplace-api/v1/manage/storefront-video/replace-gallery',
      request
    )
      .then((gallery) => {
        dispatch(
          receivedStorefrontVideoGallery(ActionTypes.REPLACED_STOREFRONT_VIDEO_GALLERY, gallery)
        );
        return gallery;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving video gallery.' }));
      });
  };
};

export const deleteVideo = (index: number) => {
  return (dispatch: AppDispatch) =>
    dispatch({
      type: ActionTypes.REMOVED_VIDEO,
      payload: index,
    });
};

export const editVideo = (index: number) => {
  return (dispatch: AppDispatch) =>
    dispatch({
      type: ActionTypes.EDITING_VIDEO,
      payload: index,
    });
};

export const cancelEdit = () => {
  return (dispatch: AppDispatch) =>
    dispatch({
      type: ActionTypes.CANCEL_EDIT,
    });
};

export const swapVideoOrder = (fromIndex: number, toIndex: number) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.SWAP_VIDEO_ORDER,
      payload: {
        fromIndex,
        toIndex,
      },
    });
  };
};
