import {
  REQUESTING_FLORIST_DETAILS_BY_STOREFRONT,
  RECEIVED_FLORIST_DETAILS,
  UPDATING_FLORIST_DETAILS,
  UPDATED_FLORIST_DETAILS,
  RequestingFloristDetailsByStorefrontAction,
  ReceivedFloristDetailsAction,
  FloristActionType,
  UpdatingFloristDetailsAction,
  UpdatedFloristDetailsAction,
} from '~/actions/types/floristActionTypes';
import type { AppThunk } from '~/reducers';
import { FloristDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';

const requestingFloristDetailsByStorefront = (): RequestingFloristDetailsByStorefrontAction => {
  return {
    type: REQUESTING_FLORIST_DETAILS_BY_STOREFRONT,
  };
};

export const receivedFloristDetails = (payload: FloristDetails): ReceivedFloristDetailsAction => {
  return {
    type: RECEIVED_FLORIST_DETAILS,
    payload,
  };
};

const getFlorist = (
  url: string,
  type: FloristActionType
): AppThunk<Promise<FloristDetails | void>> => {
  return (dispatch) => {
    dispatch(type);
    return ApiService.get(url)
      .then((floristDetails) => {
        dispatch(receivedFloristDetails(floristDetails));
        return floristDetails;
      })
      .catch((error) => Logger.error(error.message, error));
  };
};

export const getFloristDetailsByStorefront = (
  storefrontUuid: string
): AppThunk<Promise<FloristDetails | void>> => {
  return getFlorist(
    `/web-marketplace-api/v1/manage/florist/for-storefront/${storefrontUuid}`,
    requestingFloristDetailsByStorefront()
  );
};

/* Update florist details by id
 * everything must be included or it nulls the field
 */
const updatingFloristDetails = (): UpdatingFloristDetailsAction => {
  return {
    type: UPDATING_FLORIST_DETAILS,
  };
};

export const updatedFloristDetails = (response: FloristDetails): UpdatedFloristDetailsAction => {
  return {
    type: UPDATED_FLORIST_DETAILS,
    payload: response,
  };
};

export const updateFloristDetails = (
  florist: FloristDetails
): AppThunk<Promise<FloristDetails | void>> => {
  return (dispatch) => {
    dispatch(updatingFloristDetails());
    return ApiService.put(`/web-marketplace-api/v1/manage/florist/${florist.uuid}`, florist)
      .then((json) => {
        dispatch(updatedFloristDetails(json));
        return json;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};
