/* eslint-disable camelcase */
import {
  CatererStoryGalleryView,
  UpdateStoryCoversRequest,
  StoryCoverPhotoRequest,
  ReplaceStorefrontCoverPhotosRequest,
  UpdateStorefrontPhotosRequest,
  UploadCareCopyRequest,
} from '@zola/svc-marketplace-ts-types';
import * as toastsActions from '@zola/zola-ui/src/actions/toastsV2Actions';

import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import { SnakeCasedPropertiesDeep } from 'type-fest/index.d';

import { StoryPhotoFile } from '~/pages/vendors/Storefront/components/storyForm/meta';
import { WeddingPhotoFile } from '~/pages/vendors/Storefront/editPages/RealWeddings/meta';
import type { AppDispatch, AppThunk, RootState } from '~/reducers';
import { GalleryEntryV2, IndeterminatePhotoType } from '~/types/photoTypes';
import {
  MappedGalleryPhotoView,
  StorefrontCoverPhotoView,
  StartImageUploadJobRequest,
} from '~/types/responseTypes';
import { StorefrontCoverGalleryPhoto, VendorStorefrontDetails } from '~/types/storefrontDetails';
import { createApiAction } from '~/util/actionUtils';

import ApiService from '../../util/apiService';
import Logger from '../../util/logger';
import { setTotalPhotosInSection, moveTemp } from '../imageUploadActions';
import * as NotificationsActions from '../notificationActions';
import type {
  ImageCategories,
  ImageUploadActionPayload,
  ImageUploadedActionPayload,
  MovedImage,
  MoveImageError,
} from '../types/imageUploadActionTypes';
import * as ActionTypes from './types/vendorStorefrontPhotoActionTypes';
import {
  receivedImageUploadJobAction,
  requestingImageUploadJobAction,
  savedCoverGallery,
} from './types/vendorStorefrontPhotoActionTypes';

export interface PhotoUuid {
  uuid: string;
}
type DisplayOrder = {
  display_order: number;
  photo_uuid: string;
};
/**
 * For a list of persisted photos, apply a display order to all the
 * photos to prepare for a call to one of the endpoints that replaces
 * a photo gallery
 *
 * @param {Array<{uuid: String>}} photos
 * @return {Array<{uuid: String, display_order: number}>}
 */
const applyDisplayOrder = <T extends PhotoUuid>(photos: T[]): DisplayOrder[] => {
  return photos.map((photo: T, index) => ({
    display_order: index,
    photo_uuid: photo.uuid,
  }));
};

function creatingStorefrontPhoto(
  sectionKey: ImageCategories,
  index: number
): ActionTypes.StorefrontPhotoCreatingAction {
  const sectionData = {
    [sectionKey]: {
      [index]: { busy: true },
    },
  };
  return {
    type: ActionTypes.STOREFRONT_PHOTO_CREATING,
    payload: sectionData,
  };
}

/* batch version of above */
function creatingStorefrontPhotos(
  sectionKey: ImageCategories,
  arr: CreateStorefrontPhotoPhotoView[] = []
): ActionTypes.StorefrontPhotosCreatingAction {
  const sectionData: ImageUploadActionPayload = {
    [sectionKey]: arr.reduce(
      (result, current, index) => ({
        ...result,
        [index]: { busy: true },
      }),
      {}
    ),
  };
  return {
    type: ActionTypes.STOREFRONT_PHOTOS_CREATING,
    payload: sectionData,
  };
}

function storefrontPhotoCreated(
  sectionKey: ImageCategories,
  response: MappedGalleryPhotoView,
  index: number
): ActionTypes.StorefrontPhotoCreatedAction {
  const sectionData = {
    [sectionKey]: {
      [index]: {
        ...response,
        busy: false,
      },
    },
  };
  return {
    type: ActionTypes.STOREFRONT_PHOTO_CREATED,
    payload: sectionData,
  };
}

/* batch version of above */
function storefrontPhotosCreated(
  sectionKey: ImageCategories,
  response: MappedGalleryPhotoView[] = []
): ActionTypes.StorefrontPhotosCreatedAction {
  const sectionData: ImageUploadedActionPayload<MappedGalleryPhotoView> = {
    [sectionKey]: response.reduce(
      (result, item, index) => ({
        ...result,
        [index]: {
          ...item,
          busy: false,
        },
      }),
      {}
    ),
  };
  return {
    type: ActionTypes.STOREFRONT_PHOTOS_CREATED,
    payload: sectionData,
  };
}

export interface PhotoCreditRequest {
  photoCreditName: string;
  photoCreditReferenceVendorId?: number;
}

export interface CreateStorefrontPhotoRequest {
  userId?: number;
  height: number;
  width: number;
  imageId?: string;
  credit?: PhotoCreditRequest;
  storefrontId?: number;
}

/**
 * Creates a record in the marketplace database about photo uploaded already to the image service.
 *
 * @param {*} sectionName - front end only tracking of the uploads.
 * @param {*} storefrontId
 * @param {*} height
 * @param {*} width
 * @param {*} imageUuid
 * @param {*} fileName
 * @param {*} index - optional index in the section, Ie, this is the 3 photo of the portfolio
 */
function createStorefrontPhoto(
  sectionName: ImageCategories,
  storefrontId: number,
  height: number,
  width: number,
  imageUuid: string | undefined,
  fileName: string,
  index = 0
): AppThunk<Promise<null | MappedGalleryPhotoView>> {
  return (dispatch) => {
    dispatch(creatingStorefrontPhoto(sectionName, index));
    const body: SnakeCasedPropertiesDeep<CreateStorefrontPhotoRequest> = {
      height,
      width,
      image_id: imageUuid,
      storefront_id: storefrontId,
    };
    return ApiService.post(
      '/web-marketplace-api/v1/vendor-storefront-photo/storefront-photo',
      body,
      {},
      { 'x-record-request-body': 'true' }
    )
      .then((json: MappedGalleryPhotoView) => {
        dispatch(storefrontPhotoCreated(sectionName, json, index));
        return json;
      })
      .catch((error: Error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: `Error saving ${fileName}` }));
        return null;
      });
  };
}

export interface CreateStorefrontPhotosRequest {
  storefrontId: number;
  photos: CreateStorefrontPhotoRequest[];
}

// This is only used with createStorefrontPhotos
export interface CreateStorefrontPhotoPhotoView {
  height: number;
  width: number;
  uuid?: string;
  storefrontId?: number;
}

/* batch version of above */
function createStorefrontPhotos(
  sectionName: ImageCategories,
  storefrontId: number,
  photosArr: CreateStorefrontPhotoPhotoView[] = []
): AppThunk<Promise<MappedGalleryPhotoView[] | null>> {
  return (dispatch) => {
    dispatch(creatingStorefrontPhotos(sectionName, photosArr));
    const photos: SnakeCasedPropertiesDeep<CreateStorefrontPhotoRequest>[] = photosArr.map(
      (photo) => ({
        height: photo.height,
        width: photo.width,
        image_id: photo.uuid,
        storefront_id: photo.storefrontId,
      })
    );
    const body: SnakeCasedPropertiesDeep<CreateStorefrontPhotosRequest> = {
      storefront_id: storefrontId,
      photos,
    };
    return ApiService.post(
      '/web-marketplace-api/v1/vendor-storefront-photo/storefront-photos',
      body,
      {},
      { 'x-record-request-body': 'true' }
    )
      .then((json: MappedGalleryPhotoView[]) => {
        dispatch(storefrontPhotosCreated(sectionName, json));
        return json;
      })
      .catch((error: Error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving photos' }));
        return null;
      });
  };
}

export interface UpdateStorefrontPhotoRequest {
  imageId: string;
  credit: {
    // PhotoCreditRequest but this goes through the snake caser in the request builder
    photoCreditName: string | null;
    photoCreditReferenceVendorId?: number | null;
  };
}

export const updateStorefrontGallery = (requestObject: UpdateStorefrontPhotosRequest) =>
  // @ts-ignore: UpdateStorefrontPhotosRequest isn't a Record<string, unknown> which makes me think createApiAction is wrong
  createApiAction<MappedGalleryPhotoView[], UpdateStorefrontPhotosRequest>({
    path: `/web-marketplace-api/v1/manage/storefront-photo/gallery-photos`,
    method: 'PUT',
    requestObject,
    requestActionType: ActionTypes.UPDATING_STOREFRONT_GALLERY,
    responseActionType: ActionTypes.UPDATED_STOREFRONT_GALLERY,
  });

function savingPortfolioPhotoOrder(): ActionTypes.PortfolioPhotosSavingAction {
  return {
    type: ActionTypes.PORTFOLIO_PHOTOS_SAVING,
  };
}

function portfolioPhotoOrderSaved(
  response: MappedGalleryPhotoView[]
): ActionTypes.PortfolioPhotosSavedAction {
  return {
    type: ActionTypes.PORTFOLIO_PHOTOS_SAVED,
    payload: response,
  };
}
export enum GalleryEnum {
  STOREFRONT_COVER = 'STOREFRONT_COVER',
  STOREFRONT_GALLERY = 'STOREFRONT_GALLERY',
  VENUE_SPACE_GALLERY = 'VENUE_SPACE_GALLERY',
  CATERER_STORY_COVER_GALLERY = 'CATERER_STORY_COVER_GALLERY',
  CATERER_STORY_GALLERY = 'CATERER_STORY_GALLERY',
  BEAUTICIAN_STORY_COVER_GALLERY = 'BEAUTICIAN_STORY_COVER_GALLERY',
  BEAUTICIAN_STORY_GALLERY = 'BEAUTICIAN_STORY_GALLERY',
  WEDDING_GALLERY = 'WEDDING_GALLERY',
  SEASON_ARRANGEMENT_COVER_GALLERY = 'SEASON_ARRANGEMENT_COVER_GALLERY',
}
export enum ArrangementTypeEnum {
  BRIDAL_BOUQUET = 'BRIDAL_BOUQUET',
  TALL_ARRANGEMENT = 'TALL_ARRANGEMENT',
}
export interface ReplaceStorefrontGalleryRequest {
  gallery: GalleryEnum;
  galleryEntries: GalleryEntryV2[];
  storefrontId: number;
  uploadcareCopyRequests: Array<UploadCareCopyRequest> | null;
}
/**
 * Saves the portfolio photos.  Before this is called, there are _no_ changes to the gallery.
 * Photos might be uploaded, but this is were we replace the gallery with a new one.
 *
 * @param {*} storefrontId
 * @param {*} photos - list of photos, in order, to use for the portfolio
 */
export function savePortfolioPhotoOrder(
  storefrontId: number,
  photos: (AddNewPhotoResult | CreateSortablePhotoRequest)[]
): AppThunk<Promise<ActionTypes.PortfolioPhotosSavedAction | void>> {
  return (dispatch) => {
    dispatch(savingPortfolioPhotoOrder());
    const body: SnakeCasedPropertiesDeep<ReplaceStorefrontGalleryRequest> = {
      gallery: GalleryEnum.STOREFRONT_GALLERY,
      storefront_id: storefrontId,
      // Note: The display order doesn't seem to apply to this endpoint (as far as I can determine)
      // The _order_ of the photos matters, but the displayOrder only seems to be applicable on the
      // cover photos (the 2 photos that all storefronts have) and then it only seems to be used on create.
      gallery_entries: photos.map((photo) => ({
        file_name: photo.fileName,
        ucare_image_uuid: null,
        uploadcare_uuid: null,
        photo_uuid: photo.uuid || null,
        gallery_id: null,
        caption: photo.caption || null,
        arrangement_type: null,
        typical_cost_cents: null,
      })),
      uploadcare_copy_requests: null, // TODO: add new upload care copy requests
    };
    return ApiService.post('/web-marketplace-api/v1/vendor-storefront-photo/replace-gallery', body)
      .then((json: MappedGalleryPhotoView[]) => dispatch(portfolioPhotoOrderSaved(json)))
      .catch((error: Error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving portfolio photos.' }));
      });
  };
}

function savingCoverGallery(): ActionTypes.SavingCoverGalleryAction {
  return {
    type: ActionTypes.SAVING_COVER_GALLERY,
  };
}

function mapGalleryEntry(photo: IndeterminatePhotoType): GalleryEntryV2 {
  const galleryEntry: GalleryEntryV2 = {
    uploadcareUuid: null,
    photoUuid: null,
    galleryId: null,
    caption: null,
    arrangementType: null,
    typicalCostCents: null,
  };
  if ('uuid' in photo) {
    galleryEntry.photoUuid = photo.uuid;
  }
  if ('photoUuid' in photo) {
    galleryEntry.photoUuid = photo.photoUuid;
  }
  if ('caption' in photo) {
    galleryEntry.caption = photo.caption;
  }
  if ('arrangementType' in photo) {
    galleryEntry.arrangementType = photo.arrangementType;
  }
  if ('typicalCostCents' in photo) {
    galleryEntry.typicalCostCents = photo.typicalCostCents;
  }
  if ('caption' in photo) {
    galleryEntry.caption = photo.caption;
  }
  if ('ucareImageUuid' in photo) {
    galleryEntry.uploadcareUuid = photo.ucareImageUuid || null;
  }
  return galleryEntry;
}

/**
 * Sets the photos for the cover gallery, like our other gallery save operations. Before
 * this is called, there are no changes to the cover gallery.  The photos provided should
 * already have been uploaded to the storefront and this will replace the gallery with
 * the new storefront photo records.
 *
 * @param {} storefrontUuid
 * @param {} storefrontId
 * @param {Array<{photo_uuid: String}>} photos
 */
export function saveCoverGallery(
  storefrontUuid: string,
  storefrontId: number,
  photos: Array<IndeterminatePhotoType>
): AppThunk<Promise<StorefrontCoverPhotoView[] | void>> {
  // separate new and existing photos into separate request fields
  const ucarePhotos = photos.filter((photo) =>
    Object.prototype.hasOwnProperty.call(photo, 'ucareImageUuid')
  ) as StoryPhotoFile[];
  const uploadCareCopyRequests: UploadCareCopyRequest[] = prepareUcareCopyRequests(ucarePhotos);
  const galleryEntries = photos.map(mapGalleryEntry);
  const body: ReplaceStorefrontCoverPhotosRequest = {
    gallery: GalleryEnum.STOREFRONT_COVER,
    galleryEntries,
    storefrontId,
    uploadcareCopyRequests: uploadCareCopyRequests,
  };

  return (dispatch) => {
    dispatch(savingCoverGallery());
    return ApiService.put(
      // This one goes through the request mapper (app builder)
      `/web-marketplace-api/v1/manage/storefront-photo/${storefrontUuid}/cover`,
      body
    )
      .then((json: StorefrontCoverGalleryPhoto[]) => {
        dispatch(savedCoverGallery(json));
        return json;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving cover photos.' }));
      });
  };
}

const prepareUcareCopyRequests = (tmpPhotos: StoryPhotoFile[]): UploadCareCopyRequest[] => {
  const requests: UploadCareCopyRequest[] = [];
  tmpPhotos.forEach((t) =>
    requests.push({
      uploadCareReference: t.imageUrl || t.ucareImageUuid || '',
    })
  );
  return requests;
};
export interface SaveSortablePhotoRequest {
  s3Bucket: string;
  s3Key: string;
  contentType: string;
  fileName: string;
  caption?: string | null;
  height: number;
  width: number;
  newPhoto?: boolean;
  imageUrl: string;
  index: number;
  uuid: string;
  // There might be more fields for an already saved photo (newPhoto = false)
}

interface SavedTempPhotoResult {
  uuid: string;
  fileName: string;
  height: number;
  width: number;
  src: string;
  caption?: string | null;
  newPhoto?: boolean;
}

/**
 * Persists information about a photo in svc-image.  The photo may
 * be in S3, but not svc-image.  If that is the case, this creates the
 * entry in svc-image, giving us back a uuid that we can use to do display
 * the image.
 *
 * @param {*} sectionKey
 * @param {*} photo
 * @param {*} index
 */
function saveSortablePhoto(
  sectionKey: ImageCategories,
  photo: SaveSortablePhotoRequest,
  index: number
): AppThunk<Promise<SavedTempPhotoResult | SaveSortablePhotoRequest>> {
  return (dispatch: AppDispatch) => {
    const saveTempPhoto = () =>
      dispatch(
        moveTemp(sectionKey, photo.s3Bucket, photo.s3Key, photo.contentType, photo.fileName, index)
      ).then((data: MovedImage | MoveImageError) => {
        // This isn't handling a MoveImageError and never has

        const result: SavedTempPhotoResult = {
          // @ts-ignore: uuid isn't return if there was an error and my brain is not figuring out how to handle the union type, so just keep things compatible
          uuid: data.uuid,
          fileName: photo.fileName,
          height: photo.height,
          width: photo.width,
          // @ts-ignore: url isn't return if there was an error and my brain is not figuring out how to handle the union type, so just keep things compatible
          src: data.url,
          newPhoto: photo.newPhoto || false,
          caption: photo.caption,
        };
        return result;
      });

    return photo.newPhoto ? saveTempPhoto() : Promise.resolve(photo);
  };
}

interface CreateSortablePhotoRequest extends CreateStorefrontPhotoPhotoView {
  fileName: string;
  caption?: string | null;
  src?: string;
  newPhoto?: boolean;
}

interface AddNewPhotoResult extends Partial<MappedGalleryPhotoView> {
  fileName: string;
  caption?: string | null;
  src?: string;
}
/**
 * Ensure that a photo has been created in the storefront photos table.  This
 * takes a single photo, that may have just been uploaded to svc-image.  If it
 * was just uploaded, the newPhoto field will be set, and that tells us we need
 * to create a record in storefront photos. If newPhoto is not set, the photo
 * was already created and this is a no-op, just returning the photo.
 *
 * @param {*} sectionKey
 * @param {*} photo
 * @param {*} index
 */
function createSortablePhoto(
  sectionKey: ImageCategories,
  photo: CreateSortablePhotoRequest,
  index: number
): AppThunk<Promise<AddNewPhotoResult | CreateSortablePhotoRequest>> {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const storefrontDetails = getState().vendorStorefront
      .storefrontDetails as VendorStorefrontDetails;
    const addNewPhoto = () =>
      dispatch(
        createStorefrontPhoto(
          sectionKey,
          storefrontDetails.id,
          photo.height,
          photo.width,
          photo.uuid,
          photo.fileName,
          index
        )
      ).then((data: MappedGalleryPhotoView | null) => {
        const meta: AddNewPhotoResult = {
          ...data,
          fileName: photo.fileName,
          src: photo.src,
          caption: photo.caption,
        };
        return meta;
      });

    return photo.newPhoto ? addNewPhoto() : Promise.resolve(photo);
  };
}

type WithSortIndex<T> = T & {
  sortIndex: number;
};

/**
 * Batch version of createSortablePhoto, above
 *
 * Ensure that a photo or photos have been created in the storefront photos table.
 * This takes an array of photos, that may have just been uploaded to svc-image.
 * If they were just uploaded, the newPhoto field will be set, and the array split into
 * new vs. existing photos. The latter do not need to be transmitted. If newPhoto is not set,
 * the photo was already created and this is a no-op, just returning the photo.
 *
 * All items will be returned up the call chain, for updating Redux and to continue to
 * offer display order manipulation.
 *
 * @param {*} sectionKey
 * @param {*} photos
 */
function createSortablePhotos(sectionKey: ImageCategories, photos: CreateSortablePhotoRequest[]) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    // save sort order for later use
    const indexedPhotos = photos.reduce((acc, current, sortIndex) => {
      const item = {
        ...current,
        sortIndex,
      };
      acc.push(item);
      return acc;
    }, [] as WithSortIndex<CreateSortablePhotoRequest>[]);

    // divide old, new photos into separate arrays
    const groupedPhotos = _groupBy(indexedPhotos, (photo) => (photo.newPhoto ? 'new' : 'existing'));
    const newPhotos = groupedPhotos.new || [];
    const existingPhotos = groupedPhotos.existing || [];
    const storefrontDetails = getState().vendorStorefront
      .storefrontDetails as VendorStorefrontDetails;
    const storefrontId = storefrontDetails.id;

    // return existing photos as thenable
    const existing = existingPhotos.map((p) => Promise.resolve(p));

    const addNewPhotos = () =>
      dispatch(createStorefrontPhotos(sectionKey, storefrontId, newPhotos)).then((dataArr) => {
        const meta: WithSortIndex<AddNewPhotoResult>[] = (dataArr || []).map(
          (data: MappedGalleryPhotoView) => {
            const photo = newPhotos.find(
              (p) => p.uuid === data.uuid
            ) as WithSortIndex<CreateSortablePhotoRequest>;

            const result: WithSortIndex<AddNewPhotoResult> = {
              ...data,
              fileName: photo.fileName,
              src: photo.src,
              caption: photo.caption,
              sortIndex: photo.sortIndex,
            };
            return result;
          }
        );
        return meta;
      });

    const newlySaved = addNewPhotos();
    /**
     * return all photos as a single array
     * Note: newlySaved will need further flattening once resolved
     */
    const promises = _flatten<
      | Promise<WithSortIndex<CreateSortablePhotoRequest>>
      | Promise<WithSortIndex<AddNewPhotoResult>[]>
    >([newlySaved, existing]);
    return promises;
  };
}

const createToast = (msg: string) =>
  toastsActions.positive({ headline: msg, dismissText: 'Close', autoDismissInSeconds: 10 });

type SaveSortablePhotosCallback<T> = (
  savedPhotos: (CreateSortablePhotoRequest | AddNewPhotoResult)[]
) => T;

/**
 * For a list of photos, take any photo that is a new photo (uploaded to s3, but not
 * in svc-image and storefront photos), and create the entries in svc-image and the storefront_photos.
 *
 * Optionally, after save, invoke a callback (returning that result).  The callback can be used
 * to invoke replace-gallery functions to set the photogallery.
 *
 * @param {*} sectionKey
 * @param {*} photos
 * @param {Function} callback to invoke after the photos are saved: for instance, to sort and replace the photo gallery
 *
 * @return [An array of persisted photos]
 */
export function saveSortablePhotos(
  sectionKey: ImageCategories,
  photos: SaveSortablePhotoRequest[]
): AppThunk<Promise<(CreateSortablePhotoRequest | AddNewPhotoResult)[]>>;
export function saveSortablePhotos<T>(
  sectionKey: ImageCategories,
  photos: SaveSortablePhotoRequest[],
  callback: SaveSortablePhotosCallback<T>
): AppThunk<T>;
export function saveSortablePhotos<T>(
  sectionKey: ImageCategories,
  photos: SaveSortablePhotoRequest[],
  callback?: SaveSortablePhotosCallback<T>
): AppThunk<Promise<(CreateSortablePhotoRequest | AddNewPhotoResult)[] | T>> {
  return (dispatch: AppDispatch) => {
    dispatch(setTotalPhotosInSection(sectionKey, photos.filter((photo) => photo.newPhoto).length));
    const toastAction = () => {
      dispatch(createToast('We’re uploading your photos... This may take a minute!'));
    };
    const delayedToast = setTimeout(toastAction, 10000);

    return Promise.all(
      photos.map((photo, index) => {
        return dispatch(saveSortablePhoto(sectionKey, photo, index));
      })
    )
      .then((movedPhotos) => {
        clearTimeout(delayedToast);
        return Promise.all(
          movedPhotos.map((photo, index) => dispatch(createSortablePhoto(sectionKey, photo, index)))
        );
      })
      .then((savedPhotos) => {
        if (typeof callback === 'function') {
          return callback(savedPhotos);
        }
        return savedPhotos;
      });
  };
}

/**
 * Batch version of saveSortablePhotos, for use in portfolio management page
 *
 * NOTE: large series of api calls may exhaust Cognito's patience if at any point
 * an auth token refresh is needed. As users spend lots of time on this page, and then
 * fire up to 100 photos to the service, grouping this data in a single call is implemented.
 * */
export function saveSortablePhotosV2<T>(
  sectionKey: ImageCategories,
  photos: SaveSortablePhotoRequest[],
  callback?: SaveSortablePhotosCallback<T>
) {
  return (dispatch: AppDispatch) => {
    dispatch(setTotalPhotosInSection(sectionKey, photos.filter((photo) => photo.newPhoto).length));
    const toastAction = () => {
      dispatch(createToast('We’re uploading your photos... This may take a minute!'));
    };
    const delayedToast = setTimeout(toastAction, 10000);

    return Promise.all(
      photos.map((photo, index) => dispatch(saveSortablePhoto(sectionKey, photo, index)))
    )
      .then((movedPhotos) => {
        clearTimeout(delayedToast);
        return Promise.all([dispatch(createSortablePhotos(sectionKey, movedPhotos))]);
      })
      .then(async (savedPhotos) => {
        const flattenedAndSorted = await Promise.all(_flatten(savedPhotos)).then((items = []) => {
          const sortedPhotos = _flatten(items).sort((a, b) => (a.sortIndex < b.sortIndex ? -1 : 1));
          if (typeof callback === 'function') {
            // ensure items sent back in sortIndex order
            return callback(sortedPhotos);
          }
          return sortedPhotos;
        });
        return flattenedAndSorted;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving portfolio photos.' }));
      });
  };
}

/**
 * Saves the gallery for a space (room).  Before this is called the
 * gallery has not been changed, even if photos have been upload.  This works like
 * our other galleries where we upload and create storefront photos, then
 * set the gallery in a single action.
 */
export function saveSpacePhotoOrder<T extends PhotoUuid>(
  venueUuid: string,
  spaceId: number,
  spaceUuid: string,
  photos: T[]
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(ActionTypes.spacePhotosSaving());
    const body = {
      gallery: 'VENUE_SPACE_GALLERY',
      venue_space_id: spaceId,
      gallery_entries: applyDisplayOrder(photos),
    };
    return ApiService.post(
      `/web-marketplace-api/v1/vendor-storefront-photo/venue/${venueUuid}/replace-gallery`,
      body
    )
      .then((json: MappedGalleryPhotoView[]) => {
        dispatch(ActionTypes.spacePhotosSaved({ spaceUuid, gallery: json }));
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving portfolio photos.' }));
      });
  };
}

export const updateShowcaseCoverPhotos = (
  catererUuid: string,
  storyUuid: string,
  storyId: number,
  photoRequests: StoryCoverPhotoRequest[]
): AppThunk<Promise<void>> => {
  const body: UpdateStoryCoversRequest = {
    storyId,
    photoRequests,
  };
  return (dispatch) => {
    dispatch(ActionTypes.savingCatererStoryPhotos());
    return ApiService.put<CatererStoryGalleryView[], UpdateStoryCoversRequest>(
      `/web-marketplace-api/v1/manage/caterer/${catererUuid}/story-covers`,
      body
    )
      .then((covers) => {
        dispatch(ActionTypes.savedCatererStoryPhotos({ storyUuid, covers }));
      })
      .catch((error: Error) => Logger.error(error));
  };
};

export const startImageUploadJob = (
  body: StartImageUploadJobRequest
): AppThunk<Promise<string>> => {
  const request = {
    ...body,
    tags: ['business_unit: MARKETPLACE', 'platform_type: WEB'],
  };
  return (dispatch) => {
    dispatch(requestingImageUploadJobAction());
    return ApiService.post(`/web-marketplace-api/v2/image-api/files`, request)
      .then((response: string) => {
        dispatch(receivedImageUploadJobAction(response));
        return response;
      })
      .catch((error: Error) => {
        Logger.error(error);
        throw error;
      });
  };
};

export const startImageUploadJobV2 = (
  uuid: string,
  body: StartImageUploadJobRequest
): AppThunk<Promise<string>> => {
  const request = {
    ...body,
    tags: ['business_unit: MARKETPLACE', 'platform_type: WEB'],
  };
  return (dispatch) => {
    dispatch(requestingImageUploadJobAction());
    return ApiService.put(`/web-marketplace-api/v1/manage/storefront-photo/:uuid/cover`, request)
      .then((response: string) => {
        dispatch(receivedImageUploadJobAction(response));
        return response;
      })
      .catch((error: Error) => {
        Logger.error(error);
        throw error;
      });
  };
};

export const getNewPhotos = (
  jobUuid: string,
  files: WeddingPhotoFile[],
  setPercent: (key: number) => void,
  maxPhotos: number
) => {
  return new Promise((resolve, reject) => {
    pollForImages(jobUuid, resolve, reject, files, setPercent, maxPhotos);
  });
};

const checkUploadStatus = (
  jobUuid: string,
  files: WeddingPhotoFile[],
  setPercent: (key: number) => void
) => {
  return ApiService.get(`/web-marketplace-api/v2/image-api/job/${jobUuid}`)
    .then((response) => {
      if (response?.inProgress?.length > 0) {
        const photoCount = files.filter((file) => !file.galleryId).length;
        const inProgress = ((photoCount - response.inProgress.length) / photoCount) * 100;
        setPercent(inProgress);
        return [];
      }
      setPercent(100);
      return response.completed;
    })
    .catch((error: Error) => Logger.error(error));
};

let numPolls = 0;

const pollForImages = (
  jobUuid: string,
  handleSuccess: (result: unknown) => void,
  handleError: () => void,
  files: WeddingPhotoFile[],
  setPercent: (key: number) => void,
  maxPhotos: number
) => {
  checkUploadStatus(jobUuid, files, setPercent)
    .then((result) => {
      if (result && result.length > 0) {
        handleSuccess(result);
        numPolls = 0;
      } else {
        numPolls += 1;
        if (numPolls === maxPhotos) handleError();
        setTimeout(
          pollForImages,
          2000,
          jobUuid,
          handleSuccess,
          handleError,
          files,
          setPercent,
          maxPhotos
        );
      }
    })
    .catch((error: Error) => Logger.error(error));
};
