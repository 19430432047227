import 'isomorphic-fetch';
import { ResponseType } from '@zola-helpers/server/dist/types/types.d';

import type { AppDispatch, AppThunk } from '~/reducers';

import ApiService, { handleErrors } from '../util/apiService';
import Logger from '../util/logger';
import * as NotificationsActions from './notificationActions';
import {
  IMAGE_UPLOADING,
  IMAGE_UPLOADED,
  IMAGE_MOVED,
  IMAGE_COMPLETED_CHANGED,
  IMAGE_ERRORED_CHANGED,
  TEMP_IMAGE_UPLOADING,
  TEMP_IMAGE_UPLOADED,
  TEMP_IMAGE_TOTAL_CHANGED,
  TEMP_IMAGE_COMPLETED_CHANGED,
  TEMP_IMAGE_ERRORED_CHANGED,
  ImageCategories,
  ImageUploadedAction,
  ImageUploadingAction,
  UploadedImage,
  MovedImage,
  ImageMovedAction,
  ImageTotalChangedAction,
  IMAGE_TOTAL_CHANGED,
  ImageCompletedAction,
  ImageErroredAction,
  MoveImageError,
} from './types/imageUploadActionTypes';

const uploading = (
  sectionKey: ImageCategories,
  index: number,
  temp = false
): ImageUploadingAction => {
  return {
    type: temp ? TEMP_IMAGE_UPLOADING : IMAGE_UPLOADING,
    payload: {
      [sectionKey]: {
        [index]: { busy: true },
      },
    },
  };
};

const uploaded = (
  sectionKey: ImageCategories,
  fileData: UploadedImage | { url: null },
  index: number,
  temp = false
): ImageUploadedAction => {
  return {
    type: temp ? TEMP_IMAGE_UPLOADED : IMAGE_UPLOADED,
    payload: {
      [sectionKey]: {
        [index]: {
          ...fileData,
          busy: false,
        },
      },
    },
  };
};

const fileMoved = (
  sectionKey: ImageCategories,
  fileData: MovedImage | MoveImageError,
  index = 0
): ImageMovedAction => {
  return {
    type: IMAGE_MOVED,
    payload: {
      [sectionKey]: {
        [index]: {
          ...fileData,
          busy: false,
        },
      },
    },
  };
};

type CheckImageResult = {
  path: string;
  status: 'ok' | 'error';
  width?: number;
  height?: number;
};

const checkImage = (path: string): Promise<CheckImageResult> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: 'ok', width: img.width, height: img.height });
    img.onerror = () => resolve({ path, status: 'error' }); // should this be a reject?
    img.src = path;
  });

export const setTotalPhotosInSection = (
  sectionKey: ImageCategories,
  totalPhotos: number,
  temp = false
): ImageTotalChangedAction => {
  return {
    type: temp ? TEMP_IMAGE_TOTAL_CHANGED : IMAGE_TOTAL_CHANGED,
    payload: {
      [sectionKey]: {
        totalPhotos,
        completedCount: 0,
        erroredCount: 0,
      },
    },
  };
};

const updateCompletedPhotoCount = (
  sectionKey: ImageCategories,
  temp = false
): ImageCompletedAction => {
  return {
    type: temp ? TEMP_IMAGE_COMPLETED_CHANGED : IMAGE_COMPLETED_CHANGED,
    payload: sectionKey,
  };
};

const updateErroredPhotoCount = (sectionKey: ImageCategories, temp = false): ImageErroredAction => {
  return {
    type: temp ? TEMP_IMAGE_ERRORED_CHANGED : IMAGE_ERRORED_CHANGED,
    payload: sectionKey,
  };
};

/** Error handler for the function below to return and handle errors consistently */
const uploadTempPhotoErrorResponse = (
  dispatch: AppDispatch,
  sectionKey: ImageCategories,
  index: number,
  errorMessage: string
) => {
  dispatch(updateErroredPhotoCount(sectionKey, true));
  dispatch(
    NotificationsActions.error({
      message: errorMessage,
    })
  );
  // Dispatch to clear the busy flag
  dispatch(uploaded(sectionKey, { url: null }, index, true));
  return { url: null };
};

/**
 * Uploads an image from the browser to S3.  Does not persist a record anywhere
 * in the system.  The section key is used to set busy flags in redux as the image is uploaded.
 *
 * @param {String} sectionKey - Any value the client wishes to provide to partition uploads into different sections.
 *
 * @param {Object} file - The file to upload.  Provided from the browser
 * @param {String} file.name
 * @param {String} file.size
 * @param {Object} file.exifdata
 *
 * @param {Number} index - If the section has multiple photos, an index that identifies which photo is being uploaded.
 *
 * On successful upload, returns image meta-data
 * {
 *   content_type: <String> (img/jpeg),
 *   s3_bucket: <String> ("zola-wedding-uploads")
 *   s3_key: <String ("2019/12/6/19/50/4/5dacca14-ace1-4e7f-b8aa-58a6cd155f0f.jpg")
 *   url: <String> ("//zola-wedding-uploads.s3.amazonaws.com/2019/12/6/19/50/4/5dacca14-ace1-4e7f-b8aa-58a6cd155f0f.jpg")
 *   height: <Number> (1503)
 *   width: <Number> (1000)
 * }
 *
 * On failure, dispatches a notification and returns:
 * {
 *   url: null
 * }
 */
export const uploadTempPhoto = (
  sectionKey: ImageCategories,
  file: File,
  index = 0
): AppThunk<Promise<UploadedImage | { url: null }>> => {
  return (dispatch) => {
    dispatch(uploading(sectionKey, index, true));

    // svc-image and nginx limit is 10mb
    if (file.size / 1024 / 1024 > 10) {
      return Promise.resolve(
        uploadTempPhotoErrorResponse(
          dispatch,
          sectionKey,
          index,
          `${file.name} is too big. Please resize your image to under 10mb and try again`
        )
      );
    }

    const formData = new FormData();
    formData.append('attachment', file, file.name);
    // IE 11 doesn't have full URL object support, but does support createObjectURL
    const URL = window.URL || window.webkitURL; // eslint-disable-line
    const path = URL.createObjectURL(file);

    return checkImage(path).then((img) => {
      // @ts-ignore, number / string mismatch
      formData.append('width', img.width);
      // @ts-ignore, number / string mismatch
      formData.append('height', img.height);

      return fetch('/web-marketplace-api/v1/vendor-image/temp-upload', {
        credentials: 'same-origin',
        method: 'POST',
        headers: ApiService.getCognitoHeaders() as HeadersInit,
        body: formData,
      })
        .then((response) => Promise.all([response.ok, response.json()]))
        .then(handleErrors)
        .then((response) => {
          const json = response as Omit<UploadedImage, 'width' | 'height'>;
          const metaData = {
            ...json,
            width: img.width as number,
            height: img.height as number,
          };
          dispatch(updateCompletedPhotoCount(sectionKey, true));
          dispatch(uploaded(sectionKey, metaData, index, true));
          return metaData;
        })
        .catch((error: Error) => {
          Logger.error(error.message, error);
          return uploadTempPhotoErrorResponse(
            dispatch,
            sectionKey,
            index,
            `Error uploading ${file.name}`
          );
        });
    });
  };
};

/**
 * Tells the image service to move a file from the temporary location it was uploaded to
 * and to persist record.  This creates a record in zola-database and gives us a UUID we can
 * refer to the record with.
 *
 * On successful move, returns an object like this:
 * {
 *    "uuid":"17c5db65-ccd8-490a-ac66-3c5470c54829",
 *    "url":"//localhost:9100/v2/image/17c5db65-ccd8-490a-ac66-3c5470c54829",
 *    "cloudfront_url":"//localhost:9100/v2/image/17c5db65-ccd8-490a-ac66-3c5470c54829",
 *    "busy":false
 * }
 *
 * On failure, like this:
 * {
 *    error: <String>
 * }
 *
 * On failure, an error message will be dispatched
 */
export function moveTemp(
  sectionKey: ImageCategories,
  s3Bucket: string,
  s3Key: string,
  contentType: string,
  fileName: string,
  index = 0
): AppThunk<Promise<MovedImage | MoveImageError>> {
  return (dispatch: AppDispatch) => {
    dispatch(uploading(sectionKey, index));
    const requestBody = JSON.stringify({ s3Bucket, s3Key, contentType });
    return fetch('/web-marketplace-api/v1/vendor-image/copy', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        ...ApiService.getCognitoHeaders(),
      } as HeadersInit,
      body: requestBody,
    })
      .then((response) => Promise.all([response.ok, response.json()]))
      .then(handleErrors)
      .then((response) => {
        const json = response as ResponseType<any>;
        dispatch(updateCompletedPhotoCount(sectionKey));
        dispatch(fileMoved(sectionKey, json.data, index));
        return json.data;
      })
      .catch((error: Error) => {
        Logger.error(error.message, error);
        dispatch(updateErroredPhotoCount(sectionKey));
        dispatch(NotificationsActions.error({ message: `Error uploading ${fileName}` }));
        dispatch(fileMoved(sectionKey, { error: error.message }, index));
        return { error: error.message };
      });
  };
}
