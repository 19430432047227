import {
  updatedCatererDetails,
  updatingCatererDetails,
  requestingCatererDetails,
  receivedCatererDetails,
} from '~/actions/types/catererActionTypes';
import type { AppThunk } from '~/reducers';
import { CatererStoryView, CatererDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import * as NotificationsActions from '../notificationActions';
import { receivedCatererStories, requestingCatererStories } from '../types/catererStoryActionTypes';

export const getCatererDetailsByStorefront = (
  storefrontUuid: string
): AppThunk<Promise<CatererDetails>> => {
  return (dispatch) => {
    dispatch(requestingCatererDetails());
    return ApiService.get<CatererDetails>(
      `/web-marketplace-api/v1/manage/caterer/for-storefront/${storefrontUuid}`
    ).then((caterer) => {
      dispatch(receivedCatererDetails(caterer));
      return caterer;
    });
  };
};

export const updateCatererDetails = (
  caterer: CatererDetails
): AppThunk<Promise<CatererDetails | void>> => {
  return (dispatch) => {
    dispatch(updatingCatererDetails());
    return ApiService.put(`/web-marketplace-api/v1/manage/caterer/${caterer.uuid}`, caterer)
      .then((updatedCaterer: CatererDetails) => {
        dispatch(updatedCatererDetails(updatedCaterer));
        return updatedCaterer;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};

export const getStorefrontCatererStories = (caterer: CatererDetails): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(requestingCatererStories());
    return ApiService.get<CatererStoryView[]>(
      `/web-marketplace-api/v1/manage/caterer/${caterer.id}/stories`
    ).then((stories) => {
      dispatch(
        receivedCatererStories({
          catererUuid: caterer.uuid,
          stories,
        })
      );
    });
  };
};
