import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  ReviewRequestView,
  ReviewView,
  StorefrontGalleryView,
} from '@zola/svc-marketplace-ts-types';

import cloneDeep from 'lodash/cloneDeep';

import {
  EntityFacetView,
  MappedGalleryPhotoView,
  MappedVenueMenuView,
  VenueDetails,
  VenueSpaceView,
} from '~/types/responseTypes';
import {
  CouplesStorefrontDetails,
  MappedStorefrontFaqView,
  StorefrontCoverGalleryPhoto,
  VendorStorefrontDetails,
} from '~/types/storefrontDetails';

class StorefrontDetailsBuilder<
  DetailsType extends CouplesStorefrontDetails | VendorStorefrontDetails
> {
  coverGallery: StorefrontCoverGalleryPhoto[] | MappedGalleryPhotoView[] | null;

  faqs: MappedStorefrontFaqView[] | null;

  menus: MappedVenueMenuView[] | null;

  options: EntityFacetView[] | null;

  photoGallery: StorefrontGalleryView[] | null;

  reviews: ReviewRequestView[] | null;

  spaces: VenueSpaceView[] | null;

  storefront: DetailsType;

  submittedReviews: ReviewView[] | null;

  venue: VenueDetails | null;

  constructor(storefront: DetailsType) {
    this.storefront = cloneDeep(storefront);
    this.coverGallery = null;
    this.photoGallery = null;
    this.options = null;
    this.faqs = null;
    this.reviews = null; // This is a review request
    this.submittedReviews = null;
    this.venue = null;
    this.spaces = null;
    this.menus = null;
  }

  withCoverGallery(coverGallery: StorefrontCoverGalleryPhoto[] | MappedGalleryPhotoView[]) {
    this.coverGallery = coverGallery;
    return this;
  }

  withPhotoGallery(photoGallery: StorefrontGalleryView[]) {
    this.photoGallery = photoGallery;
    return this;
  }

  withOptions(options: EntityFacetView[]) {
    this.options = options;
    return this;
  }

  withFaqs(faqs: MappedStorefrontFaqView[]) {
    this.faqs = faqs;
    return this;
  }

  withReviews(reviews: ReviewRequestView[] | null) {
    this.reviews = reviews;
    return this;
  }

  withSubmittedReviews(submittedReviews: ReviewView[] | null) {
    this.submittedReviews = submittedReviews;
    return this;
  }

  withVenue(venue: VenueDetails) {
    this.venue = venue;
    return this;
  }

  withSpaces(spaces: VenueSpaceView[]) {
    this.spaces = spaces;
    return this;
  }

  withMenus(menus: MappedVenueMenuView[]) {
    this.menus = menus;
    return this;
  }

  build() {
    if (this.coverGallery) {
      this.storefront.coverGallery = this.coverGallery;
    }

    if (this.photoGallery) {
      // @ts-expect-error this class works for now
      this.storefront.photoGallery = this.photoGallery;
    }

    if (this.options) {
      // @ts-expect-error this class works for now
      this.storefront.options = this.options;
    }

    if (this.faqs) {
      this.storefront.faqs = this.faqs;
    }

    if (this.reviews) {
      // @ts-expect-error this class works for now
      this.storefront.reviews = this.reviews;
    }

    if (this.submittedReviews) {
      // @ts-expect-error this class works for now
      this.storefront.submittedReviews = this.submittedReviews;
    }

    if (this.venue && this.storefront.taxonomyKey === VENUES_TAXONOMY_KEY) {
      this.storefront.venueDetails = this.venue;
    }

    if (this.menus && this.storefront.taxonomyKey === VENUES_TAXONOMY_KEY) {
      this.storefront.venueDetails.menus = this.menus;
    }

    if (this.spaces && this.storefront.taxonomyKey === VENUES_TAXONOMY_KEY) {
      this.storefront.venueDetails.spaces = this.spaces;
    }

    return { ...this.storefront };
  }
}

export default StorefrontDetailsBuilder;
