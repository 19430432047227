import type { AppThunk } from '~/reducers';
import { PhotographerDetails } from '~/types/responseTypes';

import ApiService from '../../util/apiService';
import Logger from '../../util/logger';
import * as NotificationsActions from '../notificationActions';
import {
  REQUESTING_PHOTOGRAPHER_DETAILS_BY_STOREFRONT,
  RECEIVED_PHOTOGRAPHER_DETAILS,
  UPDATING_PHOTOGRAPHER_DETAILS,
  UPDATED_PHOTOGRAPHER_DETAILS,
  RequestingPhotographerDetailsByStorefrontAction,
  ReceivedPhotographerDetailsAction,
  PhotographerActionType,
  UpdatingPhotographerDetailsAction,
  UpdatedPhotographerDetailsAction,
} from '../types/photographerActionTypes';

function requestingPhotographerDetailsByStorefront(): RequestingPhotographerDetailsByStorefrontAction {
  return {
    type: REQUESTING_PHOTOGRAPHER_DETAILS_BY_STOREFRONT,
  };
}

export function receivedPhotographerDetails(
  photographerDetails: PhotographerDetails
): ReceivedPhotographerDetailsAction {
  return {
    type: RECEIVED_PHOTOGRAPHER_DETAILS,
    payload: photographerDetails,
  };
}

function getPhotographer(url: string, type: PhotographerActionType): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(type);
    return ApiService.get(url)
      .then((photographerDetails) => {
        dispatch(receivedPhotographerDetails(photographerDetails));
      })
      .catch((error) => Logger.error(error.message, error));
  };
}

export function getPhotographerDetailsByStorefront(
  storefrontUuid: string
): AppThunk<Promise<void>> {
  return getPhotographer(
    `/web-marketplace-api/v1/manage/photographer/for-storefront/${storefrontUuid}`,
    requestingPhotographerDetailsByStorefront()
  );
}

/* Update Photographer details by id
 * everything must be included or it nulls the field
 */
export function updatingPhotographerDetails(): UpdatingPhotographerDetailsAction {
  return {
    type: UPDATING_PHOTOGRAPHER_DETAILS,
  };
}

export function updatedPhotographerDetails(
  response: PhotographerDetails
): UpdatedPhotographerDetailsAction {
  return {
    type: UPDATED_PHOTOGRAPHER_DETAILS,
    payload: response,
  };
}

export function updatePhotographerDetails(
  photographer: PhotographerDetails
): AppThunk<Promise<UpdatedPhotographerDetailsAction | void>> {
  return (dispatch) => {
    dispatch(updatingPhotographerDetails());
    return ApiService.put(
      `/web-marketplace-api/v1/manage/photographer/${photographer.uuid}`,
      photographer
    )
      .then((json) => dispatch(updatedPhotographerDetails(json)))
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
}
